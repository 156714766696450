<template>
	<div class="text-center full-screen" v-if="room">
		<div>
			<p class="mg-t-0 mg-b-5"><span v-if="room.mode != 'single'">Game PIN: <span class="font-60 mg-l-4">{{room.roomNumber}}</span></span><span class="font-30 mg-l-4">[{{room.groupId.length}}/{{room.amountUser}} Team]</span></p>
			
			<hr>
		</div>
		<div style="display:flex;align-items: center;height: 57%;">
		    <div class="w-100">
		    	<p class="font-60 mg-t-0">You’re in!</p>
				<p v-if="room.mode == 'workShop'" class="font-30">See your Team name on screen?</p>
				<p class="font-24 color-primary"><span id='demotext' @click="openRule()" class="cr-pointer "><i class="el-icon-collection"></i> How to play</span></p>
				<p v-if="room.mode == 'single'" class="font-30 ">Are you ready ?</p>
				<el-row class="is-flex js-center" v-if="room.mode == 'single'" >
					<el-col :span="6">
						<el-button @click="startGame()" class="mg-y-5 w-100 font-18" type="primary" round plain>Start game</el-button>
					</el-col>
				</el-row>
		    </div>
		</div>

		<div style="position: fixed; width: 100%;bottom: 0;">
		<hr>
			<p class="font-40">{{user.name}}</p>
		</div>
		<el-dialog :visible.sync="modalRule" width="50%" top="5vh" :close-on-click-modal="false" :show-close="false">
			<div >
				<swiper ref="mySwiper" :options="swiperOption" @slideChange="slideChanged($refs.mySwiper.swiper.realIndex)">
					<swiper-slide>
				       <img class="mw-100" src="@/assets/rules1.png">
				   	</swiper-slide>
				   	<swiper-slide>
				      <img class="mw-100" src="@/assets/rules2.png">
				    </swiper-slide>
            <swiper-slide>
				      <img class="mw-100" src="@/assets/pricing.png">
				    </swiper-slide>
				    <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
			        <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
			        <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
				</swiper>
                <div class="text-right ">
                    <span slot="footer" class="dialog-footer">
                    	<el-button @click="indexStep == 2 ? modalRule = false : slideChanged($refs.mySwiper.swiper.realIndex+1)" class="mg-y-5" type="primary" round >{{indexStep == 2 ? 'Done':'Next'}}</el-button>
                    </span>
                </div>
			</div>  
        </el-dialog>
        <div class="bg-backdrop" v-if="ready">
        	<div class="demo" >
			  <div class="demo__colored-blocks">
			    <div class="demo__colored-blocks-rotater">
			      <div class="demo__colored-block"></div>
			      <div class="demo__colored-block"></div>
			      <div class="demo__colored-block"></div>
			    </div>
			    <div class="demo__colored-blocks-inner"></div>
			    <div class="demo__text">Ready</div>
			  </div>
			  <div class="demo__inner">
			    <svg class="demo__numbers" viewBox="0 0 100 100">
			      <defs>
			        <path class="demo__num-path-1" d="M40,28 55,22 55,78"/>
			        <path class="demo__num-join-1-2" d="M55,78 55,83 a17,17 0 1,0 34,0 a20,10 0 0,0 -20,-10"/>
			        <path class="demo__num-path-2" d="M69,73 l-35,0 l30,-30 a16,16 0 0,0 -22.6,-22.6 l-7,7"/>
			        <path class="demo__num-join-2-3" d="M28,69 Q25,44 34.4,27.4"/>
			        <path class="demo__num-path-3" d="M30,20 60,20 40,50 a18,15 0 1,1 -12,19"/>
			      </defs>
			      <path class="demo__numbers-path" 
			            d="M-10,20 60,20 40,50 a18,15 0 1,1 -12,19 
			               Q25,44 34.4,27.4
			               l7,-7 a16,16 0 0,1 22.6,22.6 l-30,30 l35,0 L69,73 
			               a20,10 0 0,1 20,10 a17,17 0 0,1 -34,0 L55,83 
			               l0,-61 L40,28" />
			    </svg>
			  </div>
			</div>
        </div>
	</div>
</template>
<script>

import { HTTP } from'@/service/axios'
import { alertFailError } from'@/service/alertMsg'
export default {
	data(){
		return{
			ready:false,
			indexStep:0,
			modalRule:true,
			swiperOption: {
				hashNavigation: {            
					watchState: true,          
				},
				pagination: {
					el: '.swiper-pagination',
					dynamicBullets: true,
				},
				navigation: {
		            nextEl: '.swiper-button-next',
		            prevEl: '.swiper-button-prev'
		        }
	        }
		}
	},
	computed:{
		room(){
			return this.$store.state.room
		},
		user(){
			return this.$store.state.user
		}
	},
	watch:{
		room(){
			if(this.room.current != ''){
				if(this.room.current === 'Sale order' && this.room.currentRound === 1){
					this.$store.commit('SET_DATA_ROUND_MODAL',true)
				}
				this.ready = true
	        	setTimeout(()=>{
	        		this.ready = false
					if(this.room.mode == 'single'){
		        		this.$router.push('/random-payment').catch(err => {})
		        	}else{
		        		this.$router.push('/players').catch(err => {})
		        	}
	        	},3800)
			}
            let userInRoom = this.room.groupId.filter(filter => filter === this.user._id)
			if(userInRoom==null || userInRoom.length == 0){
				this.$router.push('/select-mode').catch(err => {}); 
				this.$notify.error({
					title: 'Sorry!',
					message: 'ขออภัย คุณถูกเชิญออกจากห้อง',
					type: 'error'
				});
			}
			
		}
	},
	mounted(){
		this.fetchGroup()
		this.onLobby()
	},
	methods:{
		openRule(){
			this.modalRule = true
			this.$refs.mySwiper.swiper.slideTo(0, 0)
			this.indexStep = 0
		},	
		slideChanged(data){

			this.$refs.mySwiper.swiper.slideTo(data, 0)
				this.indexStep = data
		},
		onLobby(){
			if(this.room){
				this.$socket.client.on('resGetRoom',(res)=>{
					if(this.room.roomNumber == res.obj.roomNumber){
						this.$store.commit('SET_ROOM',res.obj)
						if(res.obj.current != ''){
							this.modalRule = false
							if(res.obj.current === 'Sale Order' && res.obj.currentRound === 1){
								this.$store.commit('SET_DATA_ROUND_MODAL',true)	
							}
						}
					}	
				});
			}
		},
		fetchGroup(){
			if(this.room){
				HTTP.post('/search/room',{number:this.room.roomNumber}).then(res=>{
		
					this.$store.commit('SET_ROOM',res.data.obj)
				}).catch(e=>{
					console.log('/login error',e)
					this.alertFailError()
				})
			}else{this.$router.push('/select-mode').catch(err => {})}
        },
        async startGame(){
        	this.room.current = 'Sale Order'
			await this.$store.dispatch('updateCurrent',this.room)
        	this.ready = true
        	setTimeout(()=>{
        		this.ready = false
				if(this.room.mode == 'single'){
	        		this.$router.push('/random-payment').catch(err => {})
	        	}else{
	        		this.$router.push('/players').catch(err => {})
	        	}
        	},3800)
        }
	}
}
</script>
<style scoped>

#demotext{
	transition: all 0.3s;
	--webkit-transition: all 0.3s;
}
#demotext:hover {
	color: #000;
	text-shadow: #FFF 0px 0px 5px, #FFF 0px 0px 10px, #FFF 0px 0px 15px, #034ea1 0px 0px 20px, #034ea1 0px 0px 30px, #034ea1 0px 0px 40px, #034ea1 0px 0px 50px, #034ea1 0px 0px 75px;
}


.demo {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 500px;
  height: 140px;
  margin-top: -70px;
  padding: 10px;
  border-radius: 20px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.demo__colored-blocks {
  overflow: hidden;
  position: absolute;
  left: 50%;
  top: 0;
  width: 500px;
  height: 100%;
  margin-left: -250px;
  padding: 10px;
  border-radius: 20px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
  -webkit-animation: demoAnim 4s ease-in-out 1, contAnim 4s 1;
          animation: demoAnim 4s ease-in-out 1, contAnim 4s 1;
}
.demo__colored-blocks-rotater {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  -webkit-animation: rotation 1.3s linear infinite;
          animation: rotation 1.3s linear infinite;
}
.demo__colored-blocks-inner {
  overflow: hidden;
  position: relative;
  height: 100%;
  background: #32386D;
  border-radius: inherit;
}
.demo__colored-block {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 300%;
  height: 300%;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.demo__colored-block:nth-child(1) {
  -webkit-transform: rotate(0deg) skewX(-30deg);
          transform: rotate(0deg) skewX(-30deg);
  background-color: #FD3359;
}
.demo__colored-block:nth-child(2) {
  -webkit-transform: rotate(120deg) skewX(-30deg);
          transform: rotate(120deg) skewX(-30deg);
  background-color: #F4D302;
}
.demo__colored-block:nth-child(3) {
  -webkit-transform: rotate(240deg) skewX(-30deg);
          transform: rotate(240deg) skewX(-30deg);
  background-color: #21BDFF;
}
.demo__inner {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}
.demo__numbers {
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
}
.demo__numbers-path {
  fill: none;
  stroke-width: 10px;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 0, 518.055065155;
  stroke-dashoffset: 0;
  -webkit-animation: numAnim 4s ease-in-out infinite;
          animation: numAnim 4s ease-in-out infinite;
  opacity: 0;
}
.demo__text {
  position: absolute;
  left: 50%;
  top: 0;
  width: 500px;
  height: 100%;
  opacity: 0;
  margin-left: -250px;
  text-align: center;
  line-height: 140px;
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 15px;
  -webkit-transform: translateX(10px);
          transform: translateX(10px);
  -webkit-animation: hideText 4s 1;
          animation: hideText 4s 1;
}

@-webkit-keyframes contAnim {
  15%, 100% {
    margin-left: -250px;
    width: 500px;
  }
  25%, 90% {
    margin-left: -70px;
    width: 140px;
  }
}

@keyframes contAnim {
  15%, 100% {
    margin-left: -250px;
    width: 500px;
  }
  25%, 90% {
    margin-left: -70px;
    width: 140px;
  }
}
@-webkit-keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.055065155;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  25%, 41% {
    opacity: 1;
    stroke-dasharray: 144.4256591797, 518.055065155;
    stroke-dashoffset: -40;
  }
  53%, 66% {
    stroke-dasharray: 136.0216217041, 518.055065155;
    stroke-dashoffset: -227.238697052;
  }
  76% {
    stroke-dasharray: 113.4751205444, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  88%, 100% {
    stroke-dasharray: 72.1554946899, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes numAnim {
  15% {
    stroke-dasharray: 0, 518.055065155;
    stroke-dashoffset: 0;
    opacity: 0;
  }
  25%, 41% {
    opacity: 1;
    stroke-dasharray: 144.4256591797, 518.055065155;
    stroke-dashoffset: -40;
  }
  53%, 66% {
    stroke-dasharray: 136.0216217041, 518.055065155;
    stroke-dashoffset: -227.238697052;
  }
  76% {
    stroke-dasharray: 113.4751205444, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  88%, 100% {
    stroke-dasharray: 72.1554946899, 518.055065155;
    stroke-dashoffset: -445.8995704651;
  }
  92% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotation {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes demoAnim {
  15% {
    border-radius: 20px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  30%, 43% {
    border-radius: 50%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  52%, 65% {
    border-radius: 0;
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
  }
  78%, 90% {
    border-radius: 50%;
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
  100% {
    border-radius: 20px;
    -webkit-transform: rotate(1440deg);
            transform: rotate(1440deg);
  }
}
@keyframes demoAnim {
  15% {
    border-radius: 20px;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  30%, 43% {
    border-radius: 50%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  52%, 65% {
    border-radius: 0;
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg);
  }
  78%, 90% {
    border-radius: 50%;
    -webkit-transform: rotate(1080deg);
            transform: rotate(1080deg);
  }
  100% {
    border-radius: 20px;
    -webkit-transform: rotate(1440deg);
            transform: rotate(1440deg);
  }
}
@-webkit-keyframes hideText {
  15% {
    opacity: 1;
  }
  20%, 96% , 100%{
    opacity: 0;
  }
}
@keyframes hideText {
  15% {
    opacity: 1;
  }
  20%, 96% , 100%{
    opacity: 0;
  }
}
</style>